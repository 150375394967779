import './Privacy.scss';

function Privacy() {
    return (
        <div className='pageBackground'>
            <div className='container'>
                <h1>Privacy Policy for Outfino</h1>
                <p className={'last-updated'}><strong>Last Updated:</strong> September 27, 2024</p>
                <hr></hr>
                <h2>Introduction</h2>
                <p>Welcome to Outfino. This privacy policy outlines our practices regarding the collection, use, protection, and sharing of your information. We are committed to safeguarding the privacy of our users and ensuring transparency in our data handling processes.</p>

                <h2>How Outfino Uses Collected Image Data</h2>
                <p>We collect your image data exclusively to analyze your fashion style. Our AI algorithms assess your fashion choices to provide personalized ratings and recommendations for outfit enhancements. This data is used solely for this purpose and is not employed in any other manner.</p>

                <h2>Data Retention Policy</h2>
                <p>We retain your image data for a period of 30 days, after which it is automatically purged from our systems. During this period, the data is not utilized for any purpose other than providing you with our service.</p>

                <h2>Data Collection Methodology</h2>
                <p>Your image data is obtained directly from the photographs you upload to the app. These images undergo analysis by our proprietary algorithms, which evaluate your outfit characteristics to generate ratings and suggestions for improvement.</p>

                <h2>Data Disclosure and Sharing</h2>
                <p>Your images are not shared, sold, or disclosed to third parties, except as required by law. All image data is securely stored within our database, which remains inaccessible to external parties.</p>

                <h2>Data Security Measures</h2>
                <p>To protect your image data against unauthorized access, loss, or misuse, we implement state-of-the-art encryption methods and secure data storage technologies.</p>

                <h2>Your Rights and Choices</h2>
                <p>You possess the right to access, rectify, or delete your image data at any moment by contacting our customer support at <a href="mailto:support@outfino.com">support@outfino.com</a>.</p>

                <h2>Additional Data Collection</h2>
                <ul>
                    <li><strong>Photographs:</strong> The photographs you upload are utilized for analysis and recommendation purposes.</li>
                    <li><strong>Usage Data:</strong> We gather data on your interaction with our app, such as feature usage and engagement time, to enhance our services.</li>
                </ul>

                <h2>Data Sharing and Disclosure</h2>
                <p><strong>Third-Party Sharing:</strong> We do not engage in selling or renting your data to others.</p>

                <h2>Data Security</h2>
                <p>We employ rigorous security protocols to safeguard your data from unauthorized access, alteration, disclosure, or destruction. Nevertheless, we acknowledge that no system can guarantee absolute security.</p>

                <h2>User Rights and Control</h2>
                <ul>
                    <li><strong>Access and Correction:</strong> You are entitled to access and update your personal information.</li>
                    <li><strong>Deletion:</strong> You can request the removal of your account and related data.</li>
                    <li><strong>Consent Withdrawal:</strong> Your consent to our data processing can be revoked at any time.</li>
                </ul>

                <h2>Policy Updates</h2>
                <p>We may periodically update this privacy policy. Any significant changes will be communicated to you, and the date of the most recent update will be noted.</p>

                <h2>Contact Information</h2>
                <p>Should you have any privacy-related questions or concerns, please reach out to us at <a href="mailto:support@outfino.com?subject=Inquiry%20about%20Outfino">support@outfino.com</a>.</p>

                <h2>Compliance with Laws</h2>
                <p>Our policy adheres to relevant data protection laws, including GDPR and CCPA. We are committed to resolving any issues concerning our collection or use of your personal information.</p>

                <h2>Effective Date</h2>
                <p>This policy takes effect as of September 27, 2024.</p>

            </div>
        </div>
    )
}

export default Privacy;