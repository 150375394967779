import { useEffect } from "react";

function Download() {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = "https://apps.apple.com/hu/app/outfino/id6736884918";
        } else if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.outfino.mobile";
        } else {
            window.location.href = "/";
        }
    }, []);
    
    return (
        <div className='Download'>
            Redirecting...
        </div>
    );
}

export default Download;